import { Component, OnInit } from '@angular/core';
import { OnboardingUrlEventDataService } from './services/onboarding-url-event-data.service';

@Component({
  selector: 'hxp-customer-onboarding-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private _onboardingUrlEventDataService: OnboardingUrlEventDataService) {}

  ngOnInit() {
    this._onboardingUrlEventDataService.handleSignTerms();
  }
}
