import { NgModule } from '@angular/core';
import { ROUTES, RouterModule, Routes } from '@angular/router';
import { from, map } from 'rxjs';
import { PageDoesNotExistConfig } from '@hxp/nucleus';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { TermsComponent } from './views/terms/terms.component';
import { UsagePolicyComponent } from './views/usage-policy/usage-policy.component';

function routesFactory() {
  const ROUTES: Routes = [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'not-found',
    },
    {
      path: 'terms',
      component: TermsComponent,
    },
    {
      path: 'privacy',
      component: PrivacyComponent,
    },
    {
      path: 'usage',
      component: UsagePolicyComponent,
    },
    {
      path: 'not-found',
      loadChildren: () => import('@hxp/nucleus').then((m) => m.PageDoesNotExistModule),
      data: {
        actionLink: '/terms',
        textPath: 'onboarding-portal.views.not-found.btn-text',
        elevate: true,
      } as PageDoesNotExistConfig,
    },
    {
      path: '',
      loadChildren: () => {
        return from(import('./views/onboarding/onboarding.module')).pipe(map((m) => m.OnboardingModule));
      },
    },
    {
      path: '**',
      redirectTo: 'not-found',
    },
  ];

  return ROUTES;
}

@NgModule({
  imports: [RouterModule.forRoot([])],
  providers: [
    {
      provide: ROUTES,
      useFactory: routesFactory,
      multi: true,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
