<h1 data-testid="privacy-policy-page-content-title" class="mat-subtitle-1">HYLAND PRIVACY POLICY</h1>

<p>Effective: March 2021</p>

<p>
  This Hyland Privacy Policy (“Policy”) describes how Hyland Software, Inc. and its affiliates (“Hyland”, “we” or “us”) collect, use, and
  share the personal information we obtain when you use the Hyland Experience services or ShareBase online (collectively “Web Applications”)
  or, ShareBase Mobile Application, or ShareBase Desktop (collectively referred to as “ShareBase”). The Web Applications and ShareBase are
  collectively referred to as “the Service.” This Policy also describes your choices regarding our use of your information, the ways in
  which we safeguard such information, and how you may contact us regarding our privacy practices. In addition, this Policy includes
  information about our use of cookies.
</p>
<p>
  This Policy does not apply to your use of other Hyland services, including Hyland websites, web-based products, or other mobile
  applications. Please refer to the applicable terms of use, privacy policy, or subscription for information regarding the terms of use
  applicable to your use of those products. This policy also does not apply to the content that you upload, store or process using the
  Service (“Customer Content”). Customer Content is processed by Hyland pursuant to the applicable agreement entered between Hyland and the
  company or legal entity that licenses the Service (“Customer”).
</p>
<p>
  The Service is directed to business users and not to individual consumers seeking products or services for personal or household use. If
  you are an individual consumer seeking products or services for personal or household use, you are not authorized to use the Service.
</p>
<p>
  Hyland Software, Inc. and its affiliate entities are responsible for processing your personal information as described in this Policy.
  Contact information for each Hyland company is available
  <a href="https://www.hyland.com/en/who-we-are/location">here</a>.
</p>
<p>This policy contains the following sections:</p>
<ul>
  <li><a [routerLink]="['/privacy']" fragment="information-we-collect">Information We Collect</a></li>
  <li><a [routerLink]="['/privacy']" fragment="how-we-use-your-information">How We Use Your Information</a></li>
  <li><a [routerLink]="['/privacy']" fragment="we-share-your-information">We Share Your Information</a></li>
  <li><a [routerLink]="['/privacy']" fragment="security">Security</a></li>
  <li>
    <a [routerLink]="['/privacy']" fragment="your-choice-regarding-your-personal-information"
      >Your Choice Regarding Your Personal Information</a
    >
  </li>
  <li>
    <a [routerLink]="['/privacy']" fragment="your-choice-regarding-promotional-email">Your Choice Regarding Promotional E-mail</a>
  </li>
  <li>
    <a [routerLink]="['/privacy']" fragment="information-specific-to-users-located-outside-the-us"
      >Information Specific to Users Located Outside the United States</a
    >
  </li>
  <li><a [routerLink]="['/privacy']" fragment="english-language-controls">English Language Controls</a></li>
  <li><a [routerLink]="['/privacy']" fragment="childrens-privacy">Children’s Privacy</a></li>
  <li><a [routerLink]="['/privacy']" fragment="california-privacy-rights">California Privacy Rights</a></li>
  <li><a [routerLink]="['/privacy']" fragment="changes-to-this-policy">Changes to this Policy</a></li>
  <li><a [routerLink]="['/privacy']" fragment="contact-us">Contact Us</a></li>
</ul>

<h2 id="information-we-collect">Information We Collect</h2>
<p>
  Information You Provide To Us. We collect information you provided directly to us. For example, we collect information from you when you
  register, download or sign-in to the Service. The types of information we collect may include individually identifiable information
  (“Personal Information”), such as your name, user name, and password and any other information submitted when you create or modify a user
  profile.
</p>
<p>
  Information Third Parties Provide About You. Hyland licenses the Service to Customers. If you have been authorized by a Customer to access
  or use the Service, then that Customer may provide us with your name, user name, e-mail address or other information necessary for us to
  create or authenticate an account on your behalf.
</p>
<p>
  Information We Collect Automatically. In addition to the information you submit to us, we and our service providers automatically collect
  certain information about your use of the Service. This information may include Personal Information, such as unique device identifiers,
  dynamic and static Internet Protocol (“IP”) addresses, as well as other information, such as browser characteristics, language
  preferences, operating system details, referring URLs, length of visits, and pages viewed. Additionally, if you access the Service via a
  web browser we may also use cookies, web beacons, embedded scripts, web server logs, or other similar technologies to collect details
  about the services and devices you use to access the Service. Please see the Cookies section below for more information.
</p>
<p>
  Third Party Analytics. The Service uses third-party web analytics services, including Google Analytics, to help us analyze how users use
  the Service. These services track information about users, including, IP address, the timing of files uploaded, downloaded, shared, or
  accessed, and features utilized by you. We use this information to improve our products and services and to customize or enhance your
  experience when using the Service. Where, according to local law, IP addresses and similar identifiers are considered Personal
  Information, then we treat them as such. To learn more about how Google may use information collected through the Service,
  <a href="https://www.google.com/policies/privacy/partners/">click here</a>. If you are accessing ShareBase via a mobile device, you can
  opt out of Google Analytics from an android or iOS device, follow these instructions: (1) open the menu located in the upper left corner
  of the ShareBase Mobile Application; and (2) move the toggle switch next to “Share Usage Analytics” to “off.” If you are accessing the
  Service via a web application, you can opt out of Google Analytics by following these instruction: (1) Click the User Menu icon in the
  menu bar; and (2) Click My Account; and (3) Under Privacy & Security, click Allow usage statistics to be gathered on this device to toggle
  the setting on or off.
</p>
<p>
  We may link individually identifiable information that you provide voluntarily (e.g., your name and contact information) to the
  information that the Service collect automatically (e.g., data analytics).
</p>
<p>
  Do Not Track Setting. If you access the Service via a web browser, we track your online activities over time and across third party
  websites or online services. We also track your activities while using the Service via web browser. You may implement restrictions on
  tracking, such as plug-ins or browser settings. In that case, some features of the Service, for example, personalization or
  authentication, may not work as designed.
</p>
<p>
  Your web browser may have settings that allows you to transmit a “Do Not Track” signal when you visit various websites or use online
  services. Like many websites, the Service Web Applications are not designed to respond to “Do Not Track” signals received from browsers.
  To learn more about “Do Not Track” signals, click here.
</p>
<p>
  The ShareBase Mobile Application is not designed to respond to “Do Not Track” signals. However, the ShareBase Mobile Application does not
  track users over time or across different websites or online services nor do third parties use the ShareBase Mobile Application track
  users over time or across different websites or online services.
</p>
<h2 id="how-we-use-your-information">How We Use Your Information</h2>
<p>We may use the information, including Personal Information, we obtain through the Service for a number of purposes, including:</p>

<ul>
  <li>To improve the Service and our other products;</li>
  <li>To continuously evaluate and improve user experience;</li>
  <li>To respond to emails or other requests, comments, or questions;</li>
  <li>To provide customer support;</li>
  <li>
    To provide you or our Customer with information that we believe may be useful, such as information about products or services we offer;
  </li>
  <li>To comply with applicable laws, regulations, or legal process as well as industry standards and our company policies;</li>
  <li>
    To prevent fraud or other misuse, including to protect our rights and the rights of affiliated companies or related third parties;
  </li>
  <li>To report suspected criminal acts;</li>
  <li>To maintain records of our transactions and communications; or</li>
  <li>To monitor and analyze trends, usage, and activities of users.</li>
</ul>
<p>
  We may use the Personal Information we obtain about you in other ways for which we provide notice at the time of collection. In addition,
  we may use aggregated information about the Serviceusers and information that does not identity an individual Service user in additional
  ways, without further notice.
</p>
<h2 id="we-share-your-information">How We Share Your Information</h2>
<p>
  We may share aggregated information about our users and information that does not identify any individual user, without restriction. We
  may also share your Personal Information with:
</p>
<ul>
  <li>
    Hyland Affiliates. As an international organization, Personal Information about you may be shared globally throughout Hyland’s
    international organization. A list of Hyland entities and their contact information is available here. Personal Information may be
    shared with affiliated companies for any of the purposes set forth in the section above titled “How We Use Your Information.”
  </li>
  <li>
    Third Party Service Providers Under Contract with Hyland. For example, data analytics providers, data center providers, website
    management, law firms, auditors, performance service providers, and other similar providers. We share your Personal Information with
    these Service Providers so that they can perform certain business functions on Hyland’s behalf.
  </li>
  <li>
    Third Party Solution Providers under contract with Hyland. To allow third parties that resell Hyland products and services (“Solution
    Provider”) to provide customer support and analyze trends, usage, and activities related to users affiliated with that Solution
    Provider’s customers.
  </li>
  <li>
    Other Unaffiliated Third Parties. We may also share your Personal Information with other unaffiliated third parties for the following
    purposes:
    <ul>
      <li>
        Required Disclosures. To comply with a court proceeding, in response to a court order, subpoena, civil discovery request, other
        legal process, or as otherwise required by law.
      </li>
      <li>Government Agencies. To response to a request by a government agency.</li>
      <li>
        Legal Compliance and Protections: To comply with the law or to protect the rights, property, or safety of Hyland, our users, or
        others. This may include sharing information, including Personal Information, with other companies and organizations for fraud
        protection and credit risk reduction.
      </li>
      <li>Corporate Transactions: To disclose and transfer your data, including your Personal Information, if applicable:</li>
      <ul>
        <li>To a subsequent owner, co-owner, or operator of the Service or successor database.</li>
        <li>
          In connection with a corporate merger, consolidation, bankruptcy, the sale of substantially all of our membership interests and/or
          assets or other corporate change, including to any prospective purchasers.
        </li>
      </ul>
    </ul>
  </li>
</ul>
<p>We do not rent, lease, or sell your Personal Information to third parties.</p>
<h2 id="security">Security</h2>
<p>
  We maintain appropriate physical, electronic, and administrative safeguards designed to protect the information, including the Personal
  Information, which we obtain in accordance with this Policy. Although we use reasonable efforts to secure such information, transmission
  via the Internet is not completely secure, and we cannot guarantee the confidentiality of information transmitted to us over the Internet.
</p>
<h2 id="your-choice-regarding-your-personal-information">Your Choice Regarding Your Personal Information</h2>
<p>
  To exercise your right to update, correct, or delete the Personal Information collected through the Service that is contained within the
  Customer Content, you should contact the Customer that provided you with access to the Service. Hyland will cooperate with its Customers
  in responding to these requests. To exercise your right to update, correct, delete the Personal Information collected by Hyland as
  described in this Policy, you may contact
  <a href="mailto:privacy@hyland.com">privacy@hyland.com</a>.
</p>
<p>
  If you are accessing the Service from outside the United States, please see the
  <a [routerLink]="['/privacy']" fragment="information-specific-to-users-located-outside-the-us"
    >Information Specific to Users Located Outside the United States</a
  >
  section below for additional information on accessing your Personal Information and other legal rights available to you under applicable
  law.
</p>
<p>
  <i id="your-choice-regarding-promotional-email">Your Choice Regarding Promotional Email.</i> You may unsubscribe from receiving
  promotional emails from us by following the instructions provided in those email communications. Please note that even if you opt-out of
  receiving promotional communications from us, we may continue to send you non-promotional emails, such as emails concerning our ongoing
  business relationship with you.
</p>
<p>
  <i>Your Choices About Certain Other Data Collection Technologies.</i> You have a number of options to control or limit how we and our
  service providers use cookies and other data collection technologies including for advertising:
</p>
<ul>
  <li>To prevent your information from being used by Google Analytics:</li>
  <ul>
    <li>
      you can install Google’s opt-out browser add-on:
      <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
    </li>
    <li>
      from your android or iOS device, follow these instructions: (1) open the menu located in the upper left corner of the ShareBase Mobile
      Application,(2) selected the Settings menu; and (3) toggle switch next to “Share Usage Statistics” to “off.”
    </li>
  </ul>
  <li>
    To opt out of interest-based advertising, you can visit
    <a href="http://optout.networkadvertising.org/#!/">http://optout.networkadvertising.org/#!/</a> and follow NAI’s on-screen instructions.
    Note that if you opt out through the NAI, you will still receive advertising, but the advertising will not be tailored to your
    interests. In addition, if you opt out through NAI and later delete your cookies, use a different browser, or buy a new device, you will
    need you opt out of interest-based advertising again.
  </li>
  <li>
    Check your mobile device for settings that control ads based on your interactions with the applications on your device. For example, on
    your iOS device, enable the “Limit Ad Tracking” setting, and on your Android device, enable the “Opt out of Ads Personalization”
    setting.
  </li>
</ul>
<h2 id="information-specific-to-users-located-outside-the-us">Information Specific to Users Located Outside the United States</h2>
<p>
  The Service is operated by Hyland Software, Inc., which is headquartered in the United States at 28500 Clemens Rd. Westlake, Ohio 44145.
  The Personal Information collected by the Service is controlled by the Hyland entity that licensed the Service to the Customer applicable
  to you. Contact information for each Hyland affiliated company is available here.
</p>
<p>
  <i>Data Retention.</i> We retain your Personal Information for as long as the Customer that provided you with access to the Service
  continues to license the Service and for thirty (30) days thereafter, unless a longer period is required by law or otherwise agreed upon
  between Hyland and Customer. You may request that your Personal Information be deleted as described below.
</p>
<p><i>Users Located in Brazil</i></p>
<p>
  The following applies specifically to individuals located in Brazil. If you are located in Brazil, you have the right, subject to
  applicable law, to obtain the following:
</p>
<ul>
  <li>Confirmation that Hyland processes your Personal Information;</li>
  <li>Access to your Personal Information from Hyland;</li>
  <li>Correction of your Personal Information if incomplete, inaccurate, or out-of-date;</li>
  <li>
    Anonymization, blocking or deletion of unnecessary or excessive Personal Information or Personal Information processed in noncompliance
    with the provisions of Brazil’s data protection law;
  </li>
  <li>
    Portability of your Personal Information to another service or product provider, by means of an express request and subject to
    commercial and industrial secrecy as long as that Personal Information has not already been anonymized at the time of your request;
  </li>
  <li>Deletion of personal data processed with the consent of the data subject;</li>
  <li>Information about public and private entities with which Hyland has shared your Personal Information; and</li>
  <li>Information about the possibility of denying consent and the consequences of such denial.</li>
</ul>
<p>
  You have the right to revoke consent to Hyland’s processing of your Personal Information if Hyland’s processing is based on consent. Where
  Hyland processes your Personal Information on a basis other than consent, you may oppose the processing if the processing does not comply
  with Brazilian data protection law. You have the right to petition, regarding your Personal Information, against Hyland before the
  Brazilian national authority.
</p>
<p>
  You may request to exercise your rights, or a legally constituted representative may request to exercise rights on your behalf, by
  contacting <a href="mailto:privacy@hyland.com">privacy@hyland.com</a>. To exercise your rights related to the Personal Information
  collected through the Service you should contact the Customer that provided you with access to the Service. Hyland will cooperate with its
  Customers in responding to these requests.
</p>
<p><i>Users Located in the European Economic Areas, United Kingdom, or Switzerland (Collectively the “EEA”)</i></p>
<p>The following applies specifically to individuals located in the EEA.</p>
<p>
  <u>Obligation to Provide Personal Information and Automated Decision-Making.</u> You are not required by statute or by contract to provide
  any Personal Information. We will not use your Personal Information submitted via the Service for automated decision-making, including for
  profiling, that produces legal effects or similarly significantly affects you.
</p>
<p>
  <u>Cross-Border Data Transfers:</u> The Personal Information collected via the Service may be transferred to, and processed in, countries
  outside of the country from which you have accessed the Service, including the United States, and other countries where Hyland affiliates
  are located as described above.
</p>
<p>
  Hyland Software, Inc. and all Hyland affiliated companies have agreed to process your Personal Information in accordance with the Standard
  Contractual Clauses approved by the European Commission. You can obtain a copy of the Standard Contractual Clauses by submitting a request
  to <a href="mailto:privacy@hyland.com">privacy@hyland.com</a>.
</p>
<p>
  Before transferring your Personal Information to a third party recipient located outside the EEA, Hyland will execute Standard Contractual
  Clauses, approved by the European Commission, to ensure an adequate level of protection for the transfer of your Personal Information to
  those entities outside the EU or otherwise ensure adequate protection for your Personal Information. You can obtain a copy of the relevant
  Standard Contractual Clauses by contacting
  <a href="mailto:privacy@hyland.com">privacy@hyland.com</a>.
</p>
<p>
  <u>Legal Basis For Processing:</u> Hyland processes your Personal Information with your consent and as required by law. Hyland also
  processes Personal Information as necessary for its legitimate interests as follows:
</p>
<p>
  <i>Business Operations:</i> We use your Personal Information for our legitimate business purposes, including to develop new products and
  services, to improve existing products and services, and to personalize communications.
</p>
<p>
  <i>Investigations:</i> In the event of fraud, a security incident, or a suspected criminal act, we would examine Personal Information that
  appeared to be linked to the incident as necessary to determine what happened, remediate, report to the authorities, and prevent a
  recurrence.
</p>
<p>
  <u>Right to Object to Processing for Legitimate Interests:</u> You have the right to object to the processing of your Personal Information
  for purposes of Hyland’s direct marketing or other legitimate interests by contacting
  <a href="mailto:privacy@hyland.com">privacy@hyland.com</a>.
</p>

<p>
  <u>Individual Rights:</u> You have the right to access your Personal Information or request that it be updated, corrected, or deleted. You
  also have the right to restrict certain processing of your Personal Information, as provided by applicable law and the right to data
  portability concerning your Personal Information. To exercise your rights related to the Personal Information collected through the
  Service you should contact the Customer that provided you with access to the Service. Hyland will cooperate with its Customers in
  responding to these requests.
</p>
<p>
  If you believe that your Personal Information has been processed in violation of applicable data protection law, you have the right to
  lodge a complaint with the relevant data protection authority in the country where they reside, where they work, or where the alleged
  violation occurred.
</p>
<p>
  <u>EU Representative:</u> Hyland Software, Inc.’s representative in the European Union for data protection purposes is Hyland Software UK
  Ltd. You can reach our representative at <a href="mailto:privacy@hyland.com">privacy@hyland.com</a>.
</p>
<h2>Cookies</h2>
<p>We use cookies, and embedded scripts on the Service Web Applications.</p>
<p>
  <i>Cookies:</i> A cookie is a unique identifier that is placed and stored on a computer when it is used to visit a website. Cookies may be
  used for various purposes, including to track user preferences or web pages visited while using a website, to identify the number of
  unique visitors to a website, and to verify whether or not a visitor is a repeat visitor.
</p>
<p>
  <i>Embedded Scripts:</i> An embedded script is programming code designed to collect information about a user’s interactions with a
  website. Typically, the script is temporarily downloaded onto a computer from the host website’s server (or the server of a third party
  service provider), and is active only while the visitor is connected to the website, then deleted or deactivated thereafter.
</p>
<h3>Cookies We Use And Why We Use Them</h3>
<p>We may use three types of cookies on our website:</p>
<ul>
  <li>
    <i>Essential Cookies:</i> These are cookies that are strictly necessary for the functioning of the website or for performing services
    that an individual user has requested. Some examples of functions performed by essential cookies are cookies that remember previous
    actions (e.g., entered text) when navigating back to a page in the same session.
  </li>
  <li>
    <i>Analytical Cookies:</i> These cookies collect information about how visitors use a website, for instance which pages visitors go to
    most often, and if they get error messages from web pages. These cookies don’t collect information that identifies a visitor. All
    information that analytical cookies collect is aggregated and therefore anonymous. These cookies are used only to improve how a website
    functions.
  </li>
  <li>
    <i>Functional Cookies:</i> These cookies allow the website to remember choices users make and to provide enhanced, personalized
    features. For example, on our website, these cookies remember users’ language preferences.
  </li>
</ul>
<p>Cookies can also be categorized by how long they remain on your device. There are two broad categories of duration:</p>
<ul>
  <li><i>Persistent Cookies:</i> Persistent cookies remain on your device until deleted manually or automatically.</li>
</ul>
<p><i>Session Cookies:</i> Session cookies remain on your device until you close your browser when they are automatically deleted.</p>
<p>We use both persistent cookies and session cookies on the Service Web Applications.</p>
<p>
  Cookies can be first-party cookies, which means that we place the cookies ourselves, or third party cookies, which means that a third
  party places them on your device. When you access the Service via a web browser, the Service use first-party cookies and occasionally use
  third-party cookies.
</p>
<h3>Information Collected by our Cookies and Scripts:</h3>
<p>Our cookies and scripts collect information including:</p>
<ul>
  <li>What web pages you visit on the Service and how long you visit them;</li>
  <li>Information about how you navigate, use, and interact with the Service;</li>
  <li>Your IP address, device identifiers and signatures, and browser type; and</li>
  <li>The language you’ve chosen to read the website.</li>
</ul>
<h3>Your Right To Refuse Cookies</h3>
<p>
  Many web browsers provide options to allow you to stop accepting new cookies, or to disable existing cookies. You can go to the HELP
  function of your browser to learn how. You can also go to
  <a href="https://www.cookiesandyou.com">https://www.cookiesandyou.com</a> for information about how to disable and control cookies on most
  browsers. Please be aware that if you disable the cookies on your computer you may not be able to use certain features of the Service or
  other websites, and disabling cookies may invalidate opt outs that rely on cookies to function. Also, these options may not be effective
  in all cases, or may be effective with respect to one type of browser or service, but not another.
</p>
<p>
  To opt-out of targeting by many third party advertising companies, please visit the Network Advertising Initiative (NAI) at
  <a href="http://www.networkadvertising.org">http://www.networkadvertising.org</a>.
</p>
<h2 id="childrens-privacy">Children’s Privacy</h2>
<p>
  The Service is not intended for children, nor is it targeted to children under the age of eighteen (18). We do not knowingly collect
  Personal Information from children under the age of 18 through the Service, and if we learn that we have received information from a user
  under the age of 18, we will delete such information in accordance with applicable law.
</p>
<h2 id="california-privacy-rights">California Privacy Rights</h2>
<p>
  California law permits California residents to request certain details about how their information is shared with third parties and, in
  some cases, affiliates for direct marketing purposes. California customers may request information about our compliance with this law by
  contacting us at
  <a href="mailto:privacy@hyland.com">privacy@hyland.com</a>. Any such inquiry must include “California Privacy Rights Request” in the first
  line of the description and include your name, street address, city, state, and ZIP code. Please note that we are only required to respond
  to one request per individual each year.
</p>
<p>
  Any California residents under the age of eighteen (18) who have registered to use the Service, and who have posted content or information
  to the Service, can request that such information be removed from the Service by contacting us at
  <a href="mailto:privacy@hyland.com">privacy@hyland.com</a>, stating that they personally posted such content or information and detailing
  where the content or information is posted. We will make reasonable good faith efforts to remove the post from prospective public view or
  anonymize it so the minor cannot be individually identified. This removal process cannot ensure complete or comprehensive removal.
</p>
<h2 id="english-language-controls">English Language Controls</h2>
<p>
  This Policy is executed in English and may be translated into other languages. In the event of any conflict or discrepancy between the
  English language and a translated version, the English language version shall control to the fullest extent permitted by applicable law.
</p>
<h2 id="changes-to-this-policy">Changes to this Policy</h2>
<p>
  We may change this Policy from time to time. If we make changes, we will notify you by revising the date at the top of this Policy. We
  encourage you to review this Policy whenever you visit the Service to stay informed about our information practices. This Policy was last
  modified on March 26, 2021. If we materially change this Policy in a way that affects how we use or disclose your Personal Information, we
  will provide a prominent notice and the effective date of the changes before making them.
</p>
<h2 id="contact-us">Contact Us</h2>
<p>
  To contact us regarding this Policy or with questions about our privacy practices, you may email us at
  <a href="mailto:privacy@hyland.com">privacy@hyland.com</a> (please include “Privacy Inquiry” in the subject line of your email), call us
  at 1-877-251-9504, or send postal mail to:
</p>
<p>
  Hyland Software, Inc.<br />
  Attn: PRIVACY INQUIRY
</p>
<p>Legal Department</p>
<p>
  28500 Clemens Road<br />
  Westlake OH, 44145
</p>
<p>USA</p>
