import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventDataHandlerService } from '@hxp/kernel';
import { filter } from 'rxjs/operators';

export enum OnboardingPortalUrlEventTypes {
  SignTerms = 'sign-terms',
}

export interface SignTermsEventData {
  signedQueryString: string;
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingUrlEventDataService {
  constructor(private _eventDataHandler: EventDataHandlerService, private _router: Router) {}

  handleSignTerms() {
    this._eventDataHandler.eventInformation$
      .pipe(
        filter((eventInformation) => {
          return (
            eventInformation.eventType === OnboardingPortalUrlEventTypes.SignTerms &&
            Boolean(eventInformation.eventData)
          );
        }),
      )
      .subscribe((eventInformation) => {
        const eventData = eventInformation.eventData as SignTermsEventData;
        if (eventData.signedQueryString) {
          this._navigateToGettingStarted(eventData.signedQueryString);
        }
      });
  }

  private _navigateToGettingStarted(signedQueryString: string) {
    this._router.navigateByUrl(`/getting-started${signedQueryString}`);
  }
}
