import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HyShellModule } from '@hyland/ui-shell';

import { CONFIG_PROVIDERS, EventDataModule, FeatureFlagsModule, GraphQLModule } from '@hxp/kernel';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrivacyDetailsComponent } from './views/privacy/privacy-details/privacy-details.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { UsagePolicyDetailsComponent } from './views/usage-policy/usage-policy-details/usage-policy-details.component';
import { UsagePolicyComponent } from './views/usage-policy/usage-policy.component';

@NgModule({
  declarations: [AppComponent, PrivacyComponent, PrivacyDetailsComponent, UsagePolicyComponent, UsagePolicyDetailsComponent],
  imports: [
    HyShellModule.forRoot(),
    EventDataModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    GraphQLModule,
    HttpClientModule,
    FeatureFlagsModule.forRoot({ useLocalStrategy: environment.useLocalFeatureFlags }),
  ],
  providers: [...CONFIG_PROVIDERS],
  bootstrap: [AppComponent],
})
export class AppModule {}
