<h1 data-testid="usage-policy-page-content-title" class="mat-subtitle-1">ACCEPTABLE USE POLICY ATTACHMENT</h1>
<h2>1. INTRODUCTION.</h2>
<p>
  This Acceptable Use Policy (this “AUP”) applies to all persons and entities (collectively referred to herein as “User”) who use the
  services and/or software products provided by Hyland Software, Inc. or one of its affiliates (“Hyland”) online via the Hyland Experience
  Platform applications (collectively referred to herein as the “Hyland Experience Services”). This AUP is designed to protect the security,
  integrity, reliability and privacy of Hyland’s network and the Hyland Experience Services.
</p>
<p>
  User’s use of the Hyland Experience Services constitutes User’s acceptance of the terms of use of this AUP in effect at the time of such
  use. Hyland reserves the right to modify this policy at any time effective immediately upon Hyland’s posting of the modification or
  revised AUP on Hyland’s website:
  <a href="https://www.hyland.com/community">https://www.hyland.com/community</a> or as otherwise embedded in the Hyland Experience
  Services.
</p>
<h2>2. USER OBLIGATIONS.</h2>
<p>
  2.1 Misuse. User is responsible for any misuse of the Hyland Experience Services. Therefore, User must take all reasonable precautions to
  protect access and use of any Hyland Experience Services that it uses.
</p>
<p>
  2.2 Restrictions on Use. User shall not use the Hyland Experience Services in any manner in violation of applicable law including, but not
  limited to, by:
</p>
<p>
  (a) Infringing or misappropriating intellectual property rights, including copyrights, trademarks, service marks, software, patents and
  trade secrets;
</p>
<p>
  (b) Engaging in the promotion, sale, production, fulfillment or delivery of illegal drugs, illegal gambling, obscene materials or other
  products and services prohibited by law. Similarly, soliciting illegal activities is prohibited even if such activities are not actually
  performed;
</p>
<p>(c) Displaying, transmitting, storing or making available child pornography materials;</p>
<p>
  (d) Transmitting, distributing or storing any material that is unlawful, including encryption software in violation of U.S. export control
  laws, or that presents a material risk of civil liability to Hyland;
</p>
<p>
  (e) Displaying, transmitting, storing or publishing information that constitutes libel, slander, defamation, harassment, obscenity, or
  otherwise violates the privacy or personal rights of any person;
</p>
<p>(f) Displaying or transmitting obscene, threatening, abusive or harassing messages; or</p>
<p>
  (g) Promoting, offering or implementing fraudulent financial schemes including pyramids, illegitimate funds transfers and charges to
  credit cards.
</p>
<p>2.3 Prohibited Acts. User shall not use the Hyland Experience Services to engage in any of the following:</p>
<p>
  (a) Interfering with, gaining unauthorized access to or otherwise violating the security of Hyland’s or another party’s server, network,
  personal computer, network access or control devices, software or data, or other system, or to attempt to do any of the foregoing,
  including, but not limited to, use in the development, distribution or execution of Internet viruses, worms, denial of service attacks,
  network flooding or other malicious activities intended to disrupt computer services or destroy data;
</p>
<p>
  (b) Interfering with, or disrupting, Hyland’s network or the use and enjoyment of Hyland Experience Services received by other authorized
  Users, including behavior that adversely affects access to, or performance of, the Hyland Experience Services by other Hyland customers;
</p>
<p>(c) Promoting or distributing software, services or address lists that have the purpose of facilitating spam;</p>
<p>
  (d) Providing false or misleading information in message headers or other content, using non-existent domain names or deceptive
  addressing, or hiding or obscuring information identifying a message’s point of origin or transmission path;
</p>
<p>(e) Violating personal privacy rights, except as permitted by law;</p>
<p>(f) Sending and collecting responses to spam, unsolicited electronic messages or chain mail;</p>
<p>
  (g) Use of the Hyland Experience Services in a manner not permitted by the agreement under which User has access to the Hyland Experience
  Services; and
</p>
<p>
  (h) Engaging in any activities that Hyland believes, in its sole discretion, might be harmful to Hyland’s operations, public image or
  reputation, including activity that Hyland reasonably believes could cause legal liability to Hyland.
</p>
<h2>3. ENFORCEMENT.</h2>
<p>
  In the event Hyland reasonably believes User is in violation of this AUP, Hyland may, depending on the nature and severity of the
  violation, suspend the hosting of and/or User access to any Hyland Experience Service(s) for so long as necessary for steps to be taken
  that, in Hyland’s reasonable judgment, will prevent the violation from continuing or reoccurring. If Hyland suspends any access in
  accordance with this provision, it will promptly notify User of such action.
</p>
<h2>4. NOTICE.</h2>
<p>
  Unless prohibited by law, Hyland shall provide User with written notice via e-mail or otherwise of a violation of this AUP so that such
  violation may be corrected without impact on the hosting of Hyland Experience Services; Hyland shall also provide User with a deadline for
  User to come into compliance with this AUP. Hyland reserves the right, however, to act immediately and without notice to suspend the
  hosting of Hyland Experience Services in response to a court order or government notice that certain conduct of User must be stopped or
  when Hyland reasonably determines: (1) that it may be exposed to sanction, civil liability or prosecution; (2) that such violation may
  cause harm to or interfere with the integrity or normal operations or security of Hyland’s network or networks with which Hyland is
  interconnected or interfere with another of Hyland’s customer’s use of Hyland services or software products; (3) reasonable suspicion of
  or detection of any malicious code, virus or other harmful code introduced by Customer or any Customer user; or (4) that such violation
  otherwise presents imminent risk of harm to Hyland or other of Hyland’s customers or their respective employees. In other situations,
  Hyland will use commercially reasonable efforts to provide User with at least seven (7) calendar days’ notice before suspending the
  hosting of Hyland Experience Services. User is responsible for all charges or fees due to Hyland up to the point of suspension by Hyland,
  pursuant to the agreement in place between User and Hyland related to such Hyland Experience Services.
</p>
<h2>5. DISCLAIMER.</h2>
<p>
  Hyland disclaims any responsibility for damages sustained by User as a result of Hyland’s response to User’s violation of this AUP. User
  is solely responsible for the content and messages transmitted or made available by User using the Hyland Experience Services. By using
  the Hyland Experience Services, User acknowledges that Hyland has no obligation to monitor any activities or content for violations of
  applicable law or this AUP, but it reserves the right to do so. Hyland disclaims any responsibility for inappropriate use of the Hyland
  Experience Services by User and any liability for any other third party’s violation of this AUP or applicable law.
</p>
<h2>6. INDEMNIFICATION.</h2>
<p>
  User agrees to indemnify Hyland from and against all liabilities, obligations, losses and damages, plus costs and expenses, including
  reasonable attorney’s fees, arising out of any claim, damage, loss, liability, suit or action brought against Hyland by a third party
  resulting from User’s data used with the Hyland Experience Services and/or the conduct of User in violation of this AUP.
</p>
<h2>7. WAIVER.</h2>
<p>
  No failure or delay in exercising or enforcing this policy shall constitute a waiver of the policy or of any other right or remedy. If any
  provision of this policy is deemed unenforceable due to law or change in law, such a provision shall be disregarded and the balance of the
  policy shall remain in effect.
</p>
<h2>8. QUESTIONS.</h2>
<p>If you are unsure of whether any contemplated use or action is permitted, please contact Hyland, at 440-788-5000.</p>
