<!-- cspell:words Hyland's Majeure DFARS unenforceability HYLAND Westlake Cuyahoga -->

<h1 data-testid="terms-and-conditions-page-content-title" class="mat-subtitle-1" data-testid="terms-header">HYLAND
  EXPERIENCE TERMS OF USE</h1>
<div class="terms-content" data-testid="terms-content">
  <p>
    This Hyland Experience Terms of Use is a binding agreement made between Hyland (defined below) and the person
    or entity (“Customer”) that has agreed to the terms of this Agreement by clicking the “I AGREE” option that
    appears at the bottom of this screen (“Effective Date”) and governs Customer’s use of Hyland Experience. As of the
    Effective Date, the following schedules are incorporated by reference:
  </p>
  <ul>
    <li>General Terms Schedule, available at: <a
        href="https://legal.hyland.com/Customer-Legal-Center#general-terms-schedule">https://legal.hyland.com/Customer-Legal-Center#general-terms-schedule</a>
    </li>
    <li>Hyland Experience Schedule, available at: <a
        href="https://legal.hyland.com/Customer-Legal-Center#hyland-experience-schedule">https://legal.hyland.com/Customer-Legal-Center#hyland-experience-schedule</a>
    </li>
  </ul>
  <p>
    As used herein, “Hyland” means, in any case where Customer is located in the United States or Canada,
    Hyland Software, Inc. (“Hyland”), 28105 Clemens Road, Westlake, Ohio 44145 USA, and in any case where Customer is
    located elsewhere,
    Hyland UK Operations Limited, The Place, Bridge Avenue, Maidenhead SL6 1AF, UK.
  </p>

  <p><u>INTEGRATION</u></p>

  <p>
    If Hyland and Customer have entered into a Master Agreement or document of similar import that governs Customer’s
    use
    of Hyland Experience in a production environment, then this Agreement shall have no force or effect and the terms
    and
    conditions of such other Master Agreement or document of similar import shall govern.

    <br />
    <br />

    Any terms adding to or varying from these terms, whether contained in a purchase order or other written, electronic,
    or
    oral communication shall be null and void and have no effect unless they are captured in an amendment executed by
    both parties.
  </p>

  <p><u>ACCEPTANCE</u></p>

  <p>
    BY CLICKING ON THE “I ACCEPT THE AGREEMENT” OPTION AT THE BOTTOM OF THIS SCREEN, CUSTOMER AGREES TO BE BOUND BY THE
    TERMS OF THIS AGREEMENT.
    IF CUSTOMER IS NOT WILLING TO BE BOUND BY THESE TERMS, CUSTOMER SHOULD CLICK ON THE “I DECLINE THE AGREEMENT” OPTION
    AT THE BOTTOM OF THIS SCREEN.
  </p>

  <p><u>REPRESENTATION AND WARRANTY REGARDING AUTHORITY:</u></p>

  <p>
    YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF YOURSELF AND THE COMPANY ON BEHALF OF WHICH YOU ARE ACTING.
    IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT OR YOU DO NOT HAVE THE AUTHORITY TO BIND THE COMPANY,
    DO NOT CLICK THE “I AGREE” BUTTON.

    <br />
    <br />

    BY CLICKING THE “I AGREE” BUTTON AT THE BOTTOM OF THIS SCREEN, YOU AND THE COMPANY REPRESENT AND WARRANT THAT YOU
    HAVE ALL
    REQUISITE POWER AND AUTHORITY, FOR AND ON BEHALF OF THE COMPANY, TO TAKE SUCH ACTION AND TO BIND THE COMPANY TO SUCH
    ACCEPTANCE
    AND TO THE TERMS AND CONDITIONS OF THIS AGREEMENT.
  </p>
</div>