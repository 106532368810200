import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ToolbarService } from '@hxp/kernel';

@Component({
  selector: 'hxp-customer-onboarding-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyComponent implements OnDestroy {
  constructor(private _toolbarService: ToolbarService) {
    this._toolbarService.hide();
  }

  ngOnDestroy(): void {
    this._toolbarService.show();
  }
}
