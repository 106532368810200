import { Component, OnDestroy } from '@angular/core';
import { ToolbarService } from '@hxp/kernel';

@Component({
  selector: 'hxp-customer-onboarding-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnDestroy {
  constructor(private _toolbarService: ToolbarService) {
    _toolbarService.hide();
  }

  ngOnDestroy() {
    this._toolbarService.show();
  }
}
