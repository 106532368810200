import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'hxp-customer-onboarding-privacy-details',
  templateUrl: './privacy-details.component.html',
  styleUrls: ['./privacy-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyDetailsComponent {
  constructor(router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment);
          if (element) {
            element.scrollIntoView(true);
          }
        }
      }
    });
  }
}
